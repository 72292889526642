import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "jquery-match-height";

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});

$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region slider
$(function () {
  $(".slider").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
  });
});
//#endregion
//#region class on active nav link
document.addEventListener("DOMContentLoaded", function () {
  const links = document.querySelectorAll(".nav-item .nav-link");

  links.forEach((link) => {
    let slug = link.getAttribute("href").split("/").reverse()[0];
    if (window.location.href.indexOf(slug) > -1) {
      link.classList.add("active");
    }

    if (window.location.href === link.getAttribute("href")) {
      link.classList.add("active");
    }
  });
});

//#endregion
//#region gallery

Fancybox.bind('[data-fancybox="gallery"]', {});

//#endregion
//#region animated numbers
$(function () {
  $(document).on("scroll", startCounter);
  function startCounter() {
    let scrollY = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;
    let divPos = $("#counter-container").offset().top;

    if (scrollY > divPos) {
      $(document).off("scroll", startCounter);

      $(".animation-numbers__container__number").each(function () {
        var $this = $(this);
        jQuery({
          Counter: 0,
        }).animate(
          {
            Counter: $this.text().replace(/,/g, ""),
          },
          {
            duration: 1000,
            easing: "swing",
            step: function () {
              $this.text(commaSeparateNumber(Math.floor(this.Counter)));
            },
            complete: function () {
              $this.text(commaSeparateNumber(this.Counter));
            },
          }
        );
      });

      function commaSeparateNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    }
  }
});
//#endregion
